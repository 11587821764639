import React from 'react'

import { DeploymentConfig } from '@viewlio/types/src'
import { GetGlobalPagesResponse } from '@viewlio/types/src/api/juulio'
import { FooterEntry } from '@viewlio/types/src/contentful'
import { GetStaticProps } from 'next'

import { Global } from 'components/global'
import { getEntryCached } from 'lib/contentful/client'
import { LayoutContentfulEntries } from 'lib/contentful/fetchLayoutContentfulEntries'
import { getGlobalPages } from 'lib/juulio/api'
import { getCommonStaticProps } from 'utils/dataFetching/getCommonStaticProps'

type Props = {
  footerEntry: FooterEntry
  layoutContentfulEntries: LayoutContentfulEntries
  storesByRegion: GetGlobalPagesResponse['storesByRegion']
}

const Page: React.FC<Props> = ({ ...serverProps }) => (
  <Global
    isGlobalSupport={false}
    {...serverProps}
  />
)

export const deploymentConfig: DeploymentConfig = {
  attributes: ['global'],
  environments: {
    '*': {
      'juul-ca': true,
      'juul-it': true,
      'juul-kw': true,
      'juul-uk': true,
      'juul-us': true,
    },
  },
}

export const getStaticProps: GetStaticProps = async ({
  locale,
}) => getCommonStaticProps({
  deploymentConfig,
  locale,
  metaEntryLookup: 'signin',
}, async () => ({
  ...(locale === 'en-US' && {
    footerEntry: await getEntryCached('site_footer', locale, {
      'fields.category': 'global',
    }),
  }),
  storesByRegion: (await getGlobalPages()).storesByRegion,
}))

export default Page
